import React, { useState } from 'react'
import TextInput from '../components/forms/TextInput';
import TextareaInput from '../components/forms/TextareaInput';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from 'axios';
import GoogleMapComponent from "../components/googlemap"
import Loadable from "@loadable/component"

const ContactUsPage = ({data}) => {
    
    const {register, handleSubmit, watch, errors} = useForm();
    const watchFields = watch();
    const [notifyMessage, setNotifyMessage] = useState('');
    


    const onSubmit = (data, e) => {

        // console.log(data)

        const response =  axios.post(
            '/forms/contact-form.php',
            data ,
            { headers: { 'Content-Type': 'application/json' } }
        ).then(res => {
        
            if(res.data === true){
                //console.log('true')
                setNotifyMessage('Thank you we will be in touch shortly.')
                e.target.reset();

            }else {
               // console.log('false' + res.data)
                setNotifyMessage(res.data);
            }
           
        }) 
    
      

    }

    const FormMessage = ({message}) => {
        return (
            <>
            <div className="notify-overlay" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)} role="button" tabIndex="0">
                <div className="notification" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)}  role="button" tabIndex="-1">
                    <p className="success-message">
                        {message}
                    </p>
                </div>
            </div>
            
           
            </>
        );
    }

    const OfficeImage = Loadable(() => import("../components/officeimage"));

    return (
        <Layout pageClass="contact-us-page">
        <SEO title="Contact Us" />
        <main className="main">
            <div className="typography inner">

                <h1>Contact Us</h1>

                <OfficeImage />

                <div className="contact-holder">
                    <address>

                        <div className="address">
                            <span class="red bold">Head Office:</span>
                            <span>PO Box 213,</span>
                            <span>Oxenford,</span>
                            <span>Queensland, Australia</span>
                        </div>
                        
                        
                        <div className="direct-message">
                            <span class="red bold">Other Offices:</span>
                            <span class="blue bold">NEW SOUTH WALES, VICTORIA, SOUTH AUSTRALIA & WESTERN AUSTRALIA</span>
                            <a href="tel:+61756658284" className="phone">(+61) 07 5665 8284</a>
                            <a href="tel:+61421327048" className="mobile">(+61) 0421 327 048</a>
                            <a href="mailto:support@galasolutions.com.au" className="email">support@galasolutions.com.au</a>
                        </div>
                        

                        <a href="https://www.galas.co.nz" className="aus-branch bold red">New Zealand Branch – www.galas.co.nz</a>
                
                    </address>
                

                
            
                    <div className="contact-form">
                        <p className="center">Fill out the form below and we will get back to you as soon as possible!</p>

                        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" name="bot-field" />

                            <TextInput reg={register({required: "Name is Required"})}  name="name" label="Name" type="text" errors={errors.name} />

                            <TextInput reg={register({required: "Email is Required"})}  name="email" label="Email" type="text" errors={errors.email} />

                            <TextInput reg={register()}  name="phone" label="Phone" type="text" errors={errors.phone} />

                            <TextareaInput reg={register({required: "Enquiry is Required"})}  name="enquiry" label="Enquiry"  errors={errors.enquiry} />



                            <input type="submit" className="submit-btn btn" value="Submit" />
                        </form>
                    </div> 
                </div>
                
                <GoogleMapComponent isMarkerShown/>
                
                

                
            </div>
        </main>
        {notifyMessage ? <FormMessage message={notifyMessage} /> : ''}
        </Layout>

    )
}

export const pageQuery = graphql`{
    imageSharp(resolutions: {originalName: {eq: "Office-Sign-AU-crop.jpg"}}) {
       
        big: resize(width:1200, height: 670, quality: 100) {
            src
        }
        tablet: resize(width:1000, height: 550, quality: 90) {
            src
        }
        mobile: resize(width: 460, height: 200, quality: 90) {
            src
        }
    }
  }
`
export default ContactUsPage